import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AyrshireHospiceImage from '../../images/Projects/AyrHospice.png';
import WinchburghM9Image from '../../images/Projects/WinchburghM9.png';
import WinchburghBeatlieRoadImage from '../../images/Projects/WinchburghBeatlieRoad.png';
import WinchburghTownCentreImage from '../../images/Projects/WinchburghTownCentre.png';
import CardowanImage from '../../images/Projects/Cardowan.png';
import DrumchapelImage from '../../images/Projects/Drumchapel.png';
import './ExperienceGrid.css';
import { useInView } from 'react-intersection-observer';

const experienceData = [
  { title: 'experienceGrid.ayrshireHospice.title', description: 'experienceGrid.ayrshireHospice.description', image: AyrshireHospiceImage },
  { title: 'experienceGrid.winchburghM9.title', description: 'experienceGrid.winchburghM9.description', image: WinchburghM9Image },
  { title: 'experienceGrid.winchburghBeatlieRoad.title', description: 'experienceGrid.winchburghBeatlieRoad.description', image: WinchburghBeatlieRoadImage },
  { title: 'experienceGrid.winchburghTownCentre.title', description: 'experienceGrid.winchburghTownCentre.description', image: WinchburghTownCentreImage },
  { title: 'experienceGrid.drumchapel.title', description: 'experienceGrid.drumchapel.description', image: DrumchapelImage },
  { title: 'experienceGrid.cardowan.title', description: 'experienceGrid.cardowan.description', image: CardowanImage },
];

function ExperienceGrid() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} className={`experience-grid-wrapper ${inView ? 'fade-in' : ''}`}>
      <Grid container spacing={4}>
        {experienceData.map((experience, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box className="experience-grid-card">
              <Box className="experience-grid-image-container">
                <img src={experience.image} alt={t(experience.title)} className="experience-grid-image" />
              </Box>
              <Box className="experience-grid-content-box">
                <Typography variant="h6" className="experience-grid-content-title">
                  {t(experience.title)}
                </Typography>
                <Typography variant="body2" className="experience-grid-description">
                  {t(experience.description)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ExperienceGrid;
