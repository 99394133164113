import { createTheme } from '@mui/material/styles/index.js';


export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FEFEFE',
      paper: '#FEFEFE',
    },
    text: {
      primary: '#242424', // Eerie Black
      secondary: '#545454', // Davy's Gray
    },
    primary: {
      main: '#798A92', // Slate Gray
      contrastText: '#FEFEFE', // White
    },
    secondary: {
      main: '#545454', // Davy's Gray
      contrastText: '#FEFEFE', // White
    },
    action: {
      hover: '#798A92', // Slate Gray
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#485A64', // Payne's Gray
          color: '#FEFEFE', // White
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0', // Remove border-radius for a rectangular look
          textTransform: 'none', // Disable uppercase transformation
          padding: '10px 20px', // Add padding for better spacing
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#485A64', // Matching the provided image color
            color: '#FEFEFE', // White text color
            border: '1px solid #485A64', // Border color matching the background
            '&:hover': {
              backgroundColor: '#627480', // Slightly lighter Payne's Gray for hover effect
              color: '#FEFEFE', // Ensure the text remains white on hover
            },
          },
        },
      },
    },
  },
});