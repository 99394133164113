import React from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel'; // Example icon for Professional
import GroupIcon from '@mui/icons-material/Group'; // Example icon for Collaborative
import VerifiedIcon from '@mui/icons-material/Verified'; // Example icon for Honest
import { useTranslation } from 'react-i18next';
import './Values.css';
import { useInView } from 'react-intersection-observer';

function Values() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section ref={ref} className={`values-section ${inView ? 'fade-in' : ''}`}>
      <Typography variant="h4" className="values-title">
        {t('values.title')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Box className="values-icon-box">
            <GavelIcon className="values-icon" />
          </Box>
          <Divider sx={{ pb: 2, visibility: 'hidden' }} />
          <Typography variant="h5" className="values-subtitle">
            {t('values.professionalTitle')}
          </Typography>
          <Typography variant="body1" className="value-description">
            {t('values.professionalDescription')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className="values-icon-box">
            <GroupIcon className="values-icon" />
          </Box>
          <Divider sx={{ pb: 2, visibility: 'hidden' }} />
          <Typography variant="h5" className="values-subtitle">
            {t('values.collaborativeTitle')}
          </Typography>
          <Typography variant="body1" className="value-description">
            {t('values.collaborativeDescription')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className="values-icon-box">
            <VerifiedIcon className="values-icon" />
          </Box>
          <Divider sx={{ pb: 2, visibility: 'hidden' }} />
          <Typography variant="h5" className="values-subtitle">
            {t('values.honestTitle')}
          </Typography>
          <Typography variant="body1" className="value-description">
            {t('values.honestDescription')}
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
}

export default Values;
