// src/components/ContactPage/ContactInfo.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ContactInfo.css';

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <Box className="contact-info-wrapper">
      <Box className="contact-info-container">
        <Box className="contact-section">
          <Typography variant="h5" className="contact-info-title">{t('contactInfo.contactTitle')}</Typography>
          <Typography className="contact-info-item">{t('contactInfo.companyName')}</Typography>
          <Typography className="contact-info-item">{t('contactInfo.addressLine1')}</Typography>
          <Typography className="contact-info-item">{t('contactInfo.addressLine2')}</Typography>
          <Typography className="contact-info-item">{t('contactInfo.phone')}</Typography>
          <Typography className="contact-info-item">{t('contactInfo.email')}</Typography>
        </Box>
        <Box className="media-enquiries-section">
          <Typography variant="h5" className="contact-info-title">{t('contactInfo.generalEnquiriesTitle')}</Typography>
          <Typography className="contact-info-item">
            {t('contactInfo.generalEnquiriesDescription')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactInfo;

