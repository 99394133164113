import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ProjectsGrid.css';
import BinnFarmImage from '../../images/Projects/Binn-Eco-Park-scaled.jpg';
import NorthKyleImage from '../../images/Projects/NorthKyleWindfarm.jpg';
import { useInView } from 'react-intersection-observer';

export const projectsData = [
  {
    id: 'binn-farm-efw-facility',
    titleKey: 'projectsGrid.binnFarmTitle',
    descriptionKey: 'projectsGrid.binnFarmDescription',
    projectDescriptionKey: 'projectsGrid.binnFarmProjectDescription',
    image: BinnFarmImage,
    value: '£120m',
    client: 'Paprec Engineering CNIM'
  },
  {
    id: 'north-kyle-windfarm',
    titleKey: 'projectsGrid.northKyleTitle',
    descriptionKey: 'projectsGrid.northKyleDescription',
    projectDescriptionKey: 'projectsGrid.northKyleProjectDescription',
    image: NorthKyleImage,
    value: '£300m',
    client: 'Brockwell Energy'
  }
  // More projects...
];

function ProjectsGrid() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const navigate = useNavigate();

  const handleLearnMoreClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  return (
    <div ref={ref} className={`projects-grid-content ${inView ? 'fade-in' : ''}`}>
      <Grid container spacing={4}>
        {projectsData.map((project, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Box className="projects-grid-card">
              <Box className="projects-grid-image-container">
                <img src={project.image} alt={t(project.titleKey)} className="projects-grid-image" />
              </Box>
              <Box className="projects-grid-content-box">
                <Typography variant="h6" className="projects-grid-content-title">
                  {t(project.titleKey)}
                </Typography>
                <Typography variant="body2" className="projects-grid-description">
                  {t(project.descriptionKey)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="projects-grid-button"
                  onClick={() => handleLearnMoreClick(project.id)}
                >
                  {t('projectsGrid.learnMore')}
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ProjectsGrid;
