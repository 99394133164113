// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar.tsx';
import Home from './pages/Home.tsx';
import AboutUs from './pages/AboutUs.tsx';
import Services from './pages/Services.tsx';
import Projects from './pages/Projects.tsx';
import News from './pages/News.tsx';
import Contact from './pages/Contact.tsx';
import ProjectPage from './components/Projects Page/ProjectPage.tsx'; // Import the ProjectPage component
import './App.css';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme } from './themes.js'; // Import your theme
import Footer from './components/Footer.tsx';

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <div id="root">
        <Router>
          <Navbar />
          <main className="content-wrapper">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:projectName" element={<ProjectPage />} /> {/* Dynamic project route */}
              <Route path="/news" element={<News />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
