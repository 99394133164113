import React from 'react';
import ContactForm from '../components/Contact Page/ContactForm.tsx';
import ContactInfo from '../components/Contact Page/ContactInfo.tsx';
import { Box, Divider } from '@mui/material';

function Contact() {
  return (
    <Box className="contact-page">
      <ContactInfo />
      <Divider sx={{pb: 5, visibility:'hidden'}} />
      <ContactForm />
    </Box>
  );
}

export default Contact;
