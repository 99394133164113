// src/components/ProjectPage/ProjectPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { projectsData } from '../Projects Page/ProjectsGrid.tsx';
import './ProjectPage.css';

const ProjectPage = () => {
  const { t } = useTranslation();
  const { projectName } = useParams<{ projectName: string }>();

  const project = projectsData.find(p => p.id === projectName);

  if (!project) {
    return (
      <Box className="project-page">
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
        <Typography variant="h4">{t('projectPage.projectNotFound')}</Typography>
      </Box>
    );
  }

  return (
    <Box className="project-page">
      <Box
        className="project-page-banner"
        style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)), url(${project.image})` }}
      >
        <Box className="project-page-banner-content">
          <Box className="project-page-title-container">
            <Typography variant="h4" className="project-page-title">{t(project.titleKey)}</Typography>
          </Box>
          <Typography variant="h6" className="project-page-subtitle">{t('projectPage.value')} {project.value}</Typography>
          <Typography variant="h6" className="project-page-subtitle">{t('projectPage.client')} {project.client}</Typography>
        </Box>
      </Box>
      <Box className="project-page-content">
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
        <Typography variant="h4" className="project-page-title">{t('projectPage.about')}</Typography>
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
        <Typography variant="body1" className="project-page-description">{t(project.projectDescriptionKey)}</Typography>
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
      </Box>
    </Box>
  );
};

export default ProjectPage;