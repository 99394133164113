import React from 'react';
import { Grid, Typography, Button, Box, Card, CardContent, CardMedia, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import './ServiceSection.css';
import Surveying from '../../images/Surveying.jpg';
import Commercial from '../../images/CommercialServices.jpg';
import CostManagement from '../../images/costservices.jpg';
import { useNavigate } from 'react-router-dom';

function ServiceSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    navigate('/services');
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className={'service-section'}>
      <section ref={ref} className={`service-section-content ${inView ? 'fade-in' : ''}`}>
      <Box className="service-section-header">
        <HomeIcon className="service-section-header-icon" />
        <Typography variant="h6" className="service-section-header-title">
          {t('serviceSection.header')}
        </Typography>
      </Box>
      <Typography variant="h3" className="service-section-title">
        {t('serviceSection.title')}
      </Typography>
      <Typography variant="body1" className="service-section-description">
        {t('serviceSection.description')}
      </Typography>
      <Divider sx={{ pb: 5, visibility: 'hidden' }} />
      <Grid container spacing={4} className="service-section-grid">
        <Grid item xs={12} sm={4} className="service-section-card-container">
          <Card className="service-section-card">
            <CardMedia
              component="img"
              image={Surveying}
              alt="Quantity Surveying Expertise"
              className="service-section-card-image"
            />
            <CardContent className="service-section-card-content">
              <Typography gutterBottom variant="h5" component="div" className="service-section-card-title">
                {t('serviceSection.projectManagementTitle')}
              </Typography>
              <Typography variant="body2" className="service-section-card-description">
                {t('serviceSection.projectManagementDescription')}
              </Typography>
              <Button variant="contained" color="primary" className="service-section-learn-more-button" onClick={handleLearnMoreClick}>
                {t('serviceSection.learnMore')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} className="service-section-card-container">
          <Card className="service-section-card">
            <CardMedia
              component="img"
              image={CostManagement}
              alt="Cost Management Services"
              className="service-section-card-image"
            />
            <CardContent className="service-section-card-content">
              <Typography gutterBottom variant="h5" component="div" className="service-section-card-title">
                {t('serviceSection.costManagementTitle')}
              </Typography>
              <Typography variant="body2" className="service-section-card-description">
                {t('serviceSection.costManagementDescription')}
              </Typography>
              <Button variant="contained" color="primary" className="service-section-learn-more-button" onClick={handleLearnMoreClick}>
                {t('serviceSection.learnMore')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} className="service-section-card-container">
          <Card className="service-section-card">
            <CardMedia
              component="img"
              image={Commercial}
              alt="Commercial Services"
              className="service-section-card-image"
            />
            <CardContent className="service-section-card-content">
              <Typography gutterBottom variant="h5" component="div" className="service-section-card-title">
                {t('serviceSection.commercialServicesTitle')}
              </Typography>
              <Typography variant="body2" className="service-section-card-description">
                {t('serviceSection.commercialServicesDescription')}
              </Typography>
              <Button variant="contained" color="primary" className="service-section-learn-more-button" onClick={handleLearnMoreClick}>
                {t('serviceSection.learnMore')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      </section>
    </section>
  );
}

export default ServiceSection;
