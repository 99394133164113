import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import logo from '../images/RMA Logo.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    navigate('/contact');
  };
  return (
    <section className="footer-section">
      <Grid container className="footer-grid" alignItems="center">
        <Grid item xs={12} md={4} className="footer-column footer-logo-column">
          <img src={logo} alt="Company Logo" className="footer-logo" />
        </Grid>
        <Grid item xs={12} md={6} className="footer-column footer-contact-column">
          <Typography variant="body1" className="footer-contact">
            <strong>{t('footer.phone')}</strong> +44 7905 556092
          </Typography>
          <Typography variant="body1" className="footer-contact">
            <strong>{t('footer.email')}</strong> lewis.murray@roxburghmurray.co.uk
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} className="footer-column footer-button-column">
          <Button onClick={handleLearnMoreClick} variant="contained" color="primary" className="footer-button">
            {t('footer.contactUs')}
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body2" className="footer-company-info">
        {t('footer.companyInfo')}
      </Typography>
    </section>
  );
};

export default Footer;
