import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './WorkWithUs.css';
import { useInView } from 'react-intersection-observer';

function WorkWithUs() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box className="work-with-us-container">
      <section ref={ref} className={`work-with-us-section ${inView ? 'fade-in' : ''}`}>
        <Typography variant="h4" className="work-with-us-title">
          {t('workWithUs.title')}
        </Typography>
        <Typography variant="body1" className="work-with-us-content">
          {t('workWithUs.content')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className="work-with-us-button"
          onClick={() => window.location.href = 'mailto:contact@roxburghmurray.co.uk'}>
          {t('workWithUs.contactUs')}
        </Button>
      </section>
    </Box>
  );
};

export default WorkWithUs;
