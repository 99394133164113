import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import './RecentProjects.css';
import { useNavigate } from 'react-router-dom';

// Importing images
import BinnEcoPark from '../../images/Projects/Binn-Eco-Park-scaled.jpg';
import NorthKyleWindfarm from '../../images/Projects/NorthKyleWindfarm.jpg';

const projects = [
  {
    id: 1,
    title: 'recentProjects.binnFarmTitle',
    image: BinnEcoPark,
    link: '/binnFarmEfWFacility',
    cost: '£120m'
  },
  {
    id: 2,
    title: 'recentProjects.northKyleTitle',
    image: NorthKyleWindfarm,
    link: '/northKyleWindfarm',
    cost: '£300m'
  }
];

function RecentProjects() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    navigate('/projects'); // Adjust the path as needed
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className={`recent-projects-section ${inView ? 'fade-in' : ''}`}>
      <Box className="recent-projects-header">
        <HomeIcon className="recent-projects-icon" />
        <Typography variant="subtitle2" className="recent-projects-title">
          {t('recentProjects.header')}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" className="recent-projects-main-header">
        <Typography variant="h3" className="recent-projects-main-title">
          {t('recentProjects.mainTitle')}
        </Typography>
        <Button variant="contained" color="primary" className="recent-projects-explore-more-button" onClick={handleLearnMoreClick}>
          {t('recentProjects.exploreMore')}
        </Button>
      </Box>
      <Grid container spacing={4} className="recent-projects-grid">
        {projects.map(project => (
          <Grid item xs={12} sm={6} md={6} key={project.id} className="recent-projects-grid-item">
            <Box
              className="recent-projects-project"
              style={{ backgroundImage: `url(${project.image})` }}
            >
              <Box className="recent-projects-project-overlay">
                <Typography variant="h5" className="recent-projects-project-title">
                  {t(project.title)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default RecentProjects;
