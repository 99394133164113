import './NewsFeed.css';
import React from 'react';
import { Typography, Card, CardContent } from '@mui/material';

const NewsFeed: React.FC = () => {
  return (
    <div className="newsfeed-container">
      <Typography variant="h4" className="newsfeed-title">News</Typography>
      <Card className="newsfeed-card">
        <CardContent>
          <iframe
            src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/25443745"
            frameBorder="0"
            className="newsfeed-iframe"
          ></iframe>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewsFeed;
