// src/components/ProjectsPage/ProjectsMap.tsx
import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'leaflet/dist/leaflet.css';
import './ProjectsMap.css';
import { useTranslation } from 'react-i18next';
import AyrshireHospiceImage from '../../images/Projects/AyrHospice.png';
import WinchburghM9Image from '../../images/Projects/WinchburghM9.png';
import WinchburghBeatlieRoadImage from '../../images/Projects/WinchburghBeatlieRoad.png';
import WinchburghTownCentreImage from '../../images/Projects/WinchburghTownCentre.png';
import BinnFarmImage from '../../images/Projects/Binn-Eco-Park-scaled.jpg';
import NorthKyleImage from '../../images/Projects/NorthKyleWindfarm.jpg';
import CardowanImage from '../../images/Projects/Cardowan.png';
import DrumchapelImage from '../../images/Projects/Drumchapel.png';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const projectLocations = [
  { 
    id: 'binn-farm-efw-facility',
    titleKey: 'projectsMap.binnFarmTitle', 
    lat: 56.30546, 
    lon: -3.33782, 
    image: BinnFarmImage, 
    type: 'project', 
    descriptionKey: 'projectsMap.binnFarmDescription'
  },
  { 
    id: 'north-kyle-windfarm',
    titleKey: 'projectsMap.northKyleTitle', 
    lat: 55.38155, 
    lon: -4.29248, 
    image: NorthKyleImage, 
    type: 'project', 
    descriptionKey: 'projectsMap.northKyleDescription'
  },
  { 
    id: 'ayrshire-hospice',
    titleKey: 'projectsMap.ayrshireHospiceTitle', 
    lat: 55.45374, 
    lon: -4.63721, 
    image: AyrshireHospiceImage, 
    type: 'experience', 
    descriptionKey: 'projectsMap.ayrshireHospiceDescription'
  },
  { 
    id: 'winchburgh-m9',
    titleKey: 'projectsMap.winchburghM9Title', 
    lat: 55.97047, 
    lon: -3.45787, 
    image: WinchburghM9Image, 
    type: 'experience', 
    descriptionKey: 'projectsMap.winchburghM9Description'
  },
  { 
    id: 'winchburgh-beatlie-road',
    titleKey: 'projectsMap.winchburghBeatlieRoadTitle', 
    lat: 55.96309, 
    lon: -3.45580, 
    image: WinchburghBeatlieRoadImage, 
    type: 'experience', 
    descriptionKey: 'projectsMap.winchburghBeatlieRoadDescription'
  },
  { 
    id: 'winchburgh-town-centre',
    titleKey: 'projectsMap.winchburghTownCentreTitle', 
    lat: 55.96012, 
    lon: -3.46627, 
    image: WinchburghTownCentreImage, 
    type: 'experience', 
    descriptionKey: 'projectsMap.winchburghTownCentreDescription'
  },
  { 
    id: 'drumchapel-swmp',
    titleKey: 'projectsMap.drumchapelTitle', 
    lat: 55.9134090768824, 
    lon: -4.361385600471487, 
    image: DrumchapelImage, 
    type: 'experience', 
    descriptionKey: 'projectsMap.drumchapelDescription'
  },
  { 
    id: 'cardowan-swmp',
    titleKey: 'projectsMap.cardowanTitle', 
    lat: 55.86482167708566,
    lon: -4.116070288920103, 
    image: CardowanImage, 
    type: 'experience', 
    descriptionKey: 'projectsMap.cardowanDescription'
  }
];

const customMarkerIcon = (imageSrc, type) => L.divIcon({
  className: 'custom-div-icon',
  html: `<div class='marker-pin ${type}'><img src='${imageSrc}' alt='project' class='marker-image' /></div>`,
  iconSize: [70, 70],
  iconAnchor: [35, 35],
});

function ProjectsMap() {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<{ id: string, titleKey: string, lat: number, lon: number, image: string, type: string, descriptionKey: string } | null>(null);

  const handleMarkerClick = (project) => {
    setSelectedProject(project);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setTimeout(() => setSelectedProject(null), 300); // Delay clearing project to allow animation
  };

  const navigate = useNavigate();

  const handleLearnMoreClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box ref={ref} className={`map-container ${inView ? 'fade-in' : ''}`}>
      <MapContainer center={[56.5, -4.2]} zoom={7} className="projects-map">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup>
          {projectLocations.map((project, idx) => (
            <Marker
              key={idx}
              position={[project.lat, project.lon]}
              icon={customMarkerIcon(project.image, project.type)}
              eventHandlers={{
                click: () => handleMarkerClick(project),
              }}
            />
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      <Box className={`drawer-content ${drawerOpen ? 'open' : ''}`}>
        {selectedProject && (
          <Box className={`project-drawer ${selectedProject.type}`}>
            <Box
              className="project-drawer-image"
              style={{ backgroundImage: `url(${selectedProject.image})` }}
            />
            <IconButton
              className="close-button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </IconButton>
            <Box className="project-drawer-content">
              <Typography variant="h5">{t(selectedProject.titleKey)}</Typography>
              <Typography variant="subtitle1">{selectedProject.type === 'project' ? t('projectsMap.roxburghProject') : t('projectsMap.previousExperience')}</Typography>
              <Typography variant="body1">{t(selectedProject.descriptionKey)}</Typography>
              {selectedProject.type === 'project' && (
                <Button className={'project-map-drawer-button'} variant="contained" color="primary" onClick={() => handleLearnMoreClick(selectedProject.id)}>
                  {t('projectsMap.exploreMore')}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ProjectsMap;
