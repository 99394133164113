// src/pages/Services.tsx
import React from 'react';
import { Box, Divider } from '@mui/material';
import ServicesGrid from '../components/Services Page/ServicesGrid.tsx';
import ServicesBanner from '../images/services banner.jpg';
import ResourcesGrid from '../components/Services Page/ResourcesGrid.tsx';
import './Services.css';

function Services() {
  return (
    <Box className="services-page">
      <Box className="services-banner">
        <img src={ServicesBanner} alt="Banner" className="services-banner-image" />
        <Box className="services-gradient-overlay" />
      </Box>
      <Box className="services-content">
        <ServicesGrid />
        <Divider sx={{pb: 5, visibility:'hidden'}} />
        <ResourcesGrid />
      </Box>
    </Box>
  );
}

export default Services;
