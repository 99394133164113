// src/components/ServicesPage/ServicesGrid.tsx
import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Modal, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ProjectManagementImage from '../../images/Surveying.jpg';
import CostManagementImage from '../../images/costservices.jpg';
import ProjectCommercialServicesImage from '../../images/CommercialServices.jpg';
import './ServicesGrid.css';
import { useInView } from 'react-intersection-observer';

const servicesData = [
  {
    title: 'servicesGrid.projectManagement.title',
    image: ProjectManagementImage,
    description: 'servicesGrid.projectManagement.description'
  },
  {
    title: 'servicesGrid.costManagement.title',
    image: CostManagementImage,
    description: 'servicesGrid.costManagement.description'
  },
  {
    title: 'servicesGrid.projectCommercialServices.title',
    image: ProjectCommercialServicesImage,
    description: 'servicesGrid.projectCommercialServices.description'
  },
];

function ServicesGrid() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.01,
  });

  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<{ title: string; image: string; description: string }>({ title: '', image: '', description: '' });

  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedService({ title: '', image: '', description: '' });
  };

  return (
    <Box className="services-grid">
      <div ref={ref} className={`services-grid-content ${inView ? 'fade-in' : ''}`}>
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
        <Typography variant="h4" className="services-grid-title">
          {t('servicesGrid.title')}
        </Typography>
        <Grid container spacing={4}>
          {servicesData.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} className="services-grid-item">
              <Box className="services-grid-card">
                <Box className="services-grid-image-container">
                  <img src={service.image} alt={t(service.title)} className="services-grid-image" />
                </Box>
                <Box className="services-grid-content-box">
                  <Typography variant="h6" className="services-grid-content-title">
                    {t(service.title)}
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => handleOpen(service)} className="services-grid-button">
                    {t('servicesGrid.viewMore')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Modal open={open} onClose={handleClose}>
          <Box className="services-grid-modal">
            <IconButton className="services-grid-modal-close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Box className="services-grid-modal-banner">
              <img src={selectedService.image} alt={t(selectedService.title)} className="services-grid-modal-image" />
            </Box>
            <Box className="services-grid-modal-content">
              <Typography variant="h5" className="services-grid-title">
                {t(selectedService.title)}
              </Typography>
              <Typography variant="body1" className="services-grid-modal-description">
                {t(selectedService.description)}
              </Typography>
            </Box>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};

export default ServicesGrid;
