import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './HeroSection.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import image1 from '../../images/HeroSection/1.jpg';
import image2 from '../../images/HeroSection/2.jpg';
import image3 from '../../images/HeroSection/3.jpg';
import image4 from '../../images/HeroSection/4.jpg';
import image5 from '../../images/HeroSection/5.jpg';
import image6 from '../../images/HeroSection/6.jpg';
import image7 from '../../images/HeroSection/7.jpg';
import image8 from '../../images/HeroSection/8.jpg';
import image9 from '../../images/HeroSection/9.jpg';
import image10 from '../../images/HeroSection/10.jpg';
import image11 from '../../images/HeroSection/11.jpg';
import image12 from '../../images/HeroSection/12.jpg';
import image13 from '../../images/HeroSection/13.jpg';
import image14 from '../../images/HeroSection/14.jpg';
import image15 from '../../images/HeroSection/15.jpg';
import image16 from '../../images/HeroSection/16.jpg';
import image17 from '../../images/HeroSection/17.jpg';
import image18 from '../../images/HeroSection/18.jpg';

const HeroSection = () => {
  const { t } = useTranslation();

  const imageSettings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    draggable: false,
    swipe: false,
    touchMove: false,
    pauseOnHover: false,
  };

  return (
    <Box className="hero-section">
      <Slider {...imageSettings}>
        {[
          image1, image2, image3, image4, image5, image6, image7, image8, image9,
          image10, image11, image12, image13, image14, image15, image16, image17, image18
        ].map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Hero ${index + 1}`} className="hero-section-image" />
          </div>
        ))}
      </Slider>
      <Box className="hero-section-overlay">
        <Box className="hero-section-text-container">
          <Box className="hero-section-content">
            <div className="hero-section-title-strip"></div>
            <Typography className="hero-section-title">
              {t('heroSection.title')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
