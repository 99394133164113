import React from 'react';
import NewsFeed from '../components/News Page/NewsFeed.tsx';

function News() {
  return (
    <div style={{paddingTop: '40px', paddingBottom:'20px'}}>
    <NewsFeed/>
    </div>
  );
}

export default News;
