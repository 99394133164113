import React from 'react';
import HeroSection from '../components/Home Page/HeroSection.tsx';
import ConstructionGuidance from '../components/Home Page/ConstructionGuidance.tsx';
import { Divider } from '@mui/material';
import ServiceSection from '../components/Home Page/ServiceSection.tsx';
import RecentProjects from '../components/Home Page/RecentProjects.tsx';
import ContactUs from '../components/Home Page/ContactUs.tsx';

const Home = () => {
  return (
    <div>
      <HeroSection />
      <ServiceSection />
      <Divider sx={{pb: 5, visibility:'hidden'}} />
      <RecentProjects/>
      <Divider sx={{marginBottom: 5}} />
      <ConstructionGuidance />
      <Divider sx={{pb: 5, visibility:'hidden'}} />
      <ContactUs />
      {/* Add more content here as needed */}
    </div>
  );
};

export default Home;
