// src/pages/AboutUs.tsx
import React from 'react';
import { Box } from '@mui/material';
import CompanyProfile from '../components/About Us Page/CompanyProfile.tsx';
import Team from '../components/About Us Page/Team.tsx';
import Values from '../components/About Us Page/Values.tsx';
import WorkWithUs from '../components/About Us Page/WorkWithUs.tsx';
import ClientExpertisePhoto from '../images/CompProfile1.png';
import './AboutUs.css';

function AboutUs() {
  return (
    <Box className="about-us-page">
      <Box className="about-us-banner">
        <img src={ClientExpertisePhoto} alt="Banner" className="about-us-banner-image" />
      </Box>
      <Box className="about-us-content">
        <CompanyProfile />
        <Team />
        <Values />
        <WorkWithUs />
      </Box>
    </Box>
  );
}

export default AboutUs;
