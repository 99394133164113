// src/components/AboutUsPage/CompanyProfile.tsx
import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CompanyProfile.css';
import { useInView } from 'react-intersection-observer';

function CompanyProfile() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box className="company-profile-container">
      <div ref={ref} className={`company-profile-content ${inView ? 'fade-in' : ''}`}>
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
        <Typography variant="h4" className="company-profile-title">
          {t('companyProfile.title')}
        </Typography>
        <Box className="company-profile-section">
          <Typography variant="h5" className="section-heading">
            {t('companyProfile.aboutUsTitle')}
          </Typography>
          <Typography className="section-content">
            <p>{t('companyProfile.aboutUsDescription1')}</p>
            <p>{t('companyProfile.aboutUsDescription2')}</p>
            <p>{t('companyProfile.aboutUsDescription3')}</p>
          </Typography>
        </Box>
        <Box className="company-profile-section">
          <Typography variant="h5" className="section-heading">
            {t('companyProfile.clientFocusedTitle')}
          </Typography>
          <Typography className="section-content">
            {t('companyProfile.clientFocusedDescription')}
          </Typography>
        </Box>
        <Box className="company-profile-section">
          <Typography variant="h5" className="section-heading">
            {t('companyProfile.commitmentQualityTitle')}
          </Typography>
          <Typography variant="body1" className="section-content">
            {t('companyProfile.commitmentQualityDescription')}
          </Typography>
        </Box>
      </div>
    </Box>
  );
}

export default CompanyProfile;
