import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Button, TextField, Typography, Grid, Alert, Snackbar, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ContactForm.css';

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef<HTMLFormElement | null>(null);

  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: '' });
  const [formData, setFormData] = useState({
      user_name: '',
      user_email: '',
      message: '',
  });
  const [loading, setLoading] = useState(false);
  const initialFormData = {
      user_name: '',
      user_email: '',
      message: ''
  };

  useEffect(() => {
      if (alertInfo.open) {
          const timer = setTimeout(() => {
              setAlertInfo({ ...alertInfo, open: false });
          }, 6000); // Close the alert after 6 seconds
          return () => clearTimeout(timer);
      }
  }, [alertInfo]);

  const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value,
      }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for empty fields
    if (!formData.user_name || !formData.user_email || !formData.message) {
        setLoading(false);
        setAlertInfo({ open: true, message: 'Please fill in all fields', severity: 'error' });
        return;
    }

    // EmailJS code to send email
    emailjs.send('service_fgjslht', 'template_p8kqpsh', formData, 'z2bpJs9G3jFhf9sBd')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setLoading(false);
            setAlertInfo({ open: true, message: 'Your message has been sent successfully!', severity: 'success' });
        }, (err) => {
            console.log('FAILED...', err);
            setLoading(false);
            setAlertInfo({ open: true, message: 'Failed to send the message, please try again.', severity: 'error' });
        });
    setFormData(initialFormData);
};

  return (
    <Box className="contact-page">
      <Typography variant="h4" className="contact-page-title">
        {t('contactForm.title')}
      </Typography>
      <Typography variant="body1" className="contact-page-description">
        {t('contactForm.description')}
      </Typography>
      <form ref={form} onSubmit={handleSubmit} className="contact-page-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('contactForm.name')}
              name="user_name"
              variant="outlined"
              value={formData.user_name}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('contactForm.email')}
              name="user_email"
              variant="outlined"
              value={formData.user_email}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('contactForm.message')}
              name="message"
              variant="outlined"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              required
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Box textAlign="center" marginTop={2}>
          <Button type="submit" variant="contained" color="primary" className="contact-page-button">
            {t('contactForm.sendMessage')}
          </Button>
        </Box>
      </form>
      {loading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
      {alertInfo.open && (
          <Snackbar open={alertInfo.open} autoHideDuration={6000} onClose={() => setAlertInfo({ ...alertInfo, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert onClose={() => setAlertInfo({ ...alertInfo, open: false })}
                  // @ts-ignore
                  severity={alertInfo.severity} sx={{ width: '100%' }}>
                  {alertInfo.message}
              </Alert>
          </Snackbar>
      )}
    </Box>
  );
};

export default ContactForm;
