// src/components/AboutUsPage/Team.tsx
import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Team.css';
import teamMember1 from '../../images/Lewis.jpg';
import { useInView } from 'react-intersection-observer';

const teamMembers = [
  {
    name: 'team.members.lewisMurray.name',
    position: 'team.members.lewisMurray.position',
    bio: 'team.members.lewisMurray.bio',
    photo: teamMember1,
  },
  // Add more team members here
];

function Team() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box className="team-container">
      <div ref={ref} className={`team-content ${inView ? 'fade-in' : ''}`}>
        <Typography variant="h4" className="team-title">
          {t('team.title')}
        </Typography>
        {teamMembers.map((member, index) => (
          <Card className="team-member" key={index}>
            <CardMedia
              component="img"
              image={member.photo}
              alt={t(member.name)}
              className="team-photo"
            />
            <CardContent className="team-info">
              <Typography variant="h5" className="team-name">
                {t(member.name)}
              </Typography>
              <Typography variant="subtitle1" className="team-position">
                {t(member.position)}
              </Typography>
              <Typography variant="body1" className="team-bio">
                {t(member.bio)}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </Box>
  );
}

export default Team;
