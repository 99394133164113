import React, { useState, useEffect } from 'react';
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Button, Menu, MenuItem, Typography } from '@mui/material';
import { Menu as MenuIcon, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../images/RMA Logo.png';
import darkLogo from '../images/RMA Logo - Darkmode.png';
import './Navbar.css';

const navItems = [
  { label: 'home', path: '/' },
  { label: 'aboutUs', path: '/about-us' },
  { label: 'services', path: '/services' },
  { label: 'projects', path: '/projects' },
  { label: 'news', path: '/news' },
  { label: 'contact', path: '/contact' },
];

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleMenuClose();
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} className="drawer-container">
      <div className="drawer-logo-container">
        <img src={darkLogo} alt="Roxburgh Murray Logo" className="drawer-logo" />
      </div>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton component={NavLink} to={item.path}>
              <ListItemText primary={t(`navbar.${item.label}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppBar component="nav" className="navbar">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 5, display: { md: 'none' } }}
          >
            <MenuIcon sx={{ color: '#798A92' }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <img src={logo} alt="Roxburgh Murray Logo" className="navbar-logo" />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
            {navItems.map((item) => (
              //@ts-ignore
              <Button
                key={item.label}
                component={NavLink}
                to={item.path}
                className="navbar-button"
                activeClassName="active"
                exact
              >
                {t(`navbar.${item.label}`)}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handleMenuClick}
              color="inherit"
              className="language-button"
              sx={{ backgroundColor: '#ededed', textTransform: 'uppercase' }}
              endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
            >
              {i18n.language.substring(0, 2).toUpperCase()}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={() => changeLanguage('en')}>
                <Typography>English (EN)</Typography>
                {i18n.language === 'en' && <span className="language-active-dot">•</span>}
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('fr')}>
                <Typography>Français (FR)</Typography>
                {i18n.language === 'fr' && <span className="language-active-dot">•</span>}
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('es')}>
                <Typography>Español (ES)</Typography>
                {i18n.language === 'es' && <span className="language-active-dot">•</span>}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          classes={{ paper: 'drawer-paper' }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default React.memo(Navbar);
