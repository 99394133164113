// src/pages/Projects.tsx
import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectsGrid from '../components/Projects Page/ProjectsGrid.tsx';
import ProjectsMap from '../components/Projects Page/ProjectsMap.tsx';
import ExperienceGrid from '../components/Projects Page/ExperienceGrid.tsx';
import './Projects.css';

function Projects() {
  const { t } = useTranslation();
  const [view, setView] = useState<'grid' | 'map'>('grid');

  return (
    <Box className="projects-page">
      <Box className="projects-header">
        <Typography variant="h4" className="projects-title">
          {t('projectsPage.title')}
        </Typography>
        <Box className="view-toggle-buttons">
          <Button variant={view === 'grid' ? 'contained' : 'outlined'} onClick={() => setView('grid')}>
            {t('projectsPage.gridView')}
          </Button>
          <Button variant={view === 'map' ? 'contained' : 'outlined'} onClick={() => setView('map')}>
            {t('projectsPage.mapView')}
          </Button>
        </Box>
      </Box>
      {view === 'grid' && (
        <Box className="projects-content">
          <Typography variant="h5" className="projects-section-title">
            {t('projectsPage.featuredProjects')}
          </Typography>
          <Divider sx={{ pb: 5, visibility: 'hidden' }} />
          <ProjectsGrid />
          <Divider sx={{ pb: 5, visibility: 'hidden' }} />
          <Typography variant="h5" className="projects-section-title">
            {t('projectsPage.previousExperience')}
          </Typography>
          <Divider sx={{ pb: 5, visibility: 'hidden' }} />
          <ExperienceGrid />
        </Box>
      )}
      {view === 'map' && (
        <Box className="projects-content">
          <ProjectsMap />
        </Box>
      )}
    </Box>
  );
}

export default Projects;
