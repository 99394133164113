import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import './ContactUs.css';

function ContactUs() {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className='contact-us-section'>
      <section ref={ref} className={`contact-us-content ${inView ? 'fade-in' : ''}`}>
        <Divider sx={{ pb: 5, visibility: 'hidden' }} />
        <Box className="contact-us-header">
          <HomeIcon className="contact-us-icon" />
          <Typography variant="subtitle2" className="contact-us-title">
            {t('contactUs.header')}
          </Typography>
        </Box>
        <Typography variant="h3" className="contact-us-main-title">
          {t('contactUs.mainTitle')}
        </Typography>
        <Typography variant="body1" className="contact-us-description">
          {t('contactUs.description')}
        </Typography>
      </section>
    </section>
  );
}

export default ContactUs;
