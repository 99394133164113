// src/components/ResourcesPage/ResourcesGrid.tsx
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Description as DescriptionIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import './ResourcesGrid.css';
import { useInView } from 'react-intersection-observer';

const resourcesData = [
  { title: 'resourcesGrid.necSubcontractTemplate' },
  { title: 'resourcesGrid.necCompensationEventQuotationTemplate' },
  { title: 'resourcesGrid.variationPricingTemplate' },
  { title: 'resourcesGrid.measurementTakeOffTemplates' },
  { title: 'resourcesGrid.paymentPaylessNoticeTemplate' },
  { title: 'resourcesGrid.interimApplicationTemplate' },
  { title: 'resourcesGrid.activityScheduleBoqTemplate' },
  { title: 'resourcesGrid.cashflowTemplate' },
  { title: 'resourcesGrid.vestingCertificateEnglandWalesNi' },
  { title: 'resourcesGrid.offSiteMaterialsAgreementScotland' },
  { title: 'resourcesGrid.letterOfIntentAuthorisation' },
];

function ResourcesGrid() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.01,
  });

  return (
    <Box className="resources-grid">
      <div ref={ref} className={`resources-grid-content ${inView ? 'fade-in' : ''}`}>
        <Typography variant="h4" className="resources-grid-title">
          {t('resourcesGrid.title')}
        </Typography>
        <Grid container spacing={4}>
          {resourcesData.map((resource, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} className="resources-grid-item">
              <Box className="resources-grid-icon-container">
                <div className="resources-grid-icon-circle">
                  <div className="resources-grid-icon">
                    <DescriptionIcon fontSize="large" />
                  </div>
                </div>
                <Typography variant="h6" className="resources-grid-content-title">
                  {t(resource.title)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default ResourcesGrid;
