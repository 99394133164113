import React from 'react';
import { Grid, Typography, Button, Box, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import './ConstructionGuidance.css';
import { useNavigate } from 'react-router-dom';

function ConstructionGuidance() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    navigate('/about-us');
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className={`construction-guidance-section ${inView ? 'fade-in' : ''}`}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box className="construction-guidance-why-choose-us">
            <HomeIcon className="construction-guidance-why-choose-us-icon" />
            <Typography variant="subtitle2" className="construction-guidance-why-choose-us-text">
              {t('constructionGuidance.whyChooseUs')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4" className="construction-guidance-main-title">
            {t('constructionGuidance.mainTitle')}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
          <Button variant="contained" color="primary" onClick={handleLearnMoreClick}>
            {t('constructionGuidance.exploreMore')}
          </Button>
        </Grid>
        <Grid container item spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4} style={{ textAlign: 'left' }}>
            <Box className="construction-guidance-icon-box">
              <PeopleOutlineIcon className="construction-guidance-icon" />
            </Box>
            <Divider sx={{ pb: 2, visibility: 'hidden' }} />
            <Typography variant="h5" className="construction-guidance-subtitle">
              {t('constructionGuidance.industryExpertiseTitle')}
            </Typography>
            <Typography variant="body1" className="construction-guidance-description">
              {t('constructionGuidance.industryExpertiseDescription')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: 'left' }}>
            <Box className="construction-guidance-icon-box">
              <SettingsOutlinedIcon className="construction-guidance-icon" />
            </Box>
            <Divider sx={{ pb: 2, visibility: 'hidden' }} />
            <Typography variant="h5" className="construction-guidance-subtitle">
              {t('constructionGuidance.servicesAndResourcesTitle')}
            </Typography>
            <Typography variant="body1" className="construction-guidance-description">
              {t('constructionGuidance.servicesAndResourcesDescription')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: 'left' }}>
            <Box className="construction-guidance-icon-box">
              <VerifiedUserOutlinedIcon className="construction-guidance-icon" />
            </Box>
            <Divider sx={{ pb: 2, visibility: 'hidden' }} />
            <Typography variant="h5" className="construction-guidance-subtitle">
              {t('constructionGuidance.provenTrackRecordTitle')}
            </Typography>
            <Typography variant="body1" className="construction-guidance-description">
              {t('constructionGuidance.provenTrackRecordDescription')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}

export default ConstructionGuidance;
